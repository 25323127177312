<script>
import { mapState } from "vuex";
import List from "./pages/list";
import BaseIndex from "@/views/build/content/mixins/BaseIndex";

export default {
  name: "NumberInsightIndex",
  mixins: [BaseIndex],
  components: {
    List
  },

  data() {
    return {
      selectedContent: null,
      scope: "sys",
      editable: false
    };
  },

  computed: {
    ...mapState("usages", {
      contents: state => state.numberInsightUsages
    })
  },

  mounted() {
    this.contentType = "Number Insights Usage Summary";
    this.primaryKey = "ac_id";
  }
};
</script>

<style lang="scss" scoped></style>
