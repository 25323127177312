<template>
  <div style="padding: 0 36px; width: 100%">
    <el-form class="form-container">
      <advanced-toolbar
        @dateRangeChanged="handleDateRangeChanged"
        :content-api="contentAPI"
      />
    </el-form>

    <el-scrollbar :native="true" style="margin-top: 20px;">
      <el-table
        :default-sort="{ prop: 'total_messages', order: 'descending' }"
        :data="numberInsightUsages"
        style="width: 100%"
        row-key="ac_id"
        v-loading="isLoading"
        class="list-table"
      >
        <el-table-column type="expand">
          <template slot-scope="props">
            <el-table
              :default-sort="{
                prop: 'total_usage',
                order: 'descending'
              }"
              row-key="task_id"
              :data="props.row.tasks"
            >
              <el-table-column
                sortable
                :label="__('Task')"
                prop="task.task_name"
              >
                <template slot-scope="{ row }">
                  {{ (row.task && row.task.task_name) || __("Deleted task") }}
                </template>
              </el-table-column>
              <el-table-column
                sortable
                :label="__('Total Usage')"
                prop="total_usage"
              />
              <el-table-column :label="__('Detail')">
                <template slot-scope="scope">
                  <el-button @click="handleDetailedLogClick(scope.row)">{{
                    __("More")
                  }}</el-button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          :label="__('Account')"
          prop="account.ac_name"
        />
        <el-table-column
          sortable
          :label="__('Total Usage')"
          prop="total_usage"
        />
      </el-table>
    </el-scrollbar>

    <el-dialog
      v-if="showDetailedLog"
      :visible.sync="showDetailedLog"
      custom-class="editContentItemModal"
      destroy-on-close
      fullscreen
    >
      <detailed-log :task="selectedTask"></detailed-log>
    </el-dialog>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import AdvancedToolbar from "@/components/AdvancedToolbar";
import BaseContent from "@/views/build/content/mixins/BaseContent";
import DetailedLog from "@/views/analyse/virtual-agent-usage/numberinsight/pages/DetailedLog";
import _ from "lodash";
import BackButtonHandler from "@/components/BackButtonHandler";

export default {
  name: "NumberInsightList",
  mixins: [BaseContent, BackButtonHandler],
  components: {
    AdvancedToolbar,
    DetailedLog
  },
  data() {
    return {
      showDetailedLog: false,
      selectedTask: null
    };
  },

  computed: {
    ...mapState("usages", {
      numberInsightUsages: state => state.numberInsightUsages,
      isLoading: state => state.loading
    })
  },

  methods: {
    ...mapActions("usages", {
      contentAPI: "getNumberInsightUsage",
      setTaskForDetailedLogs: "setTaskForDetailedLogs",
      setDateSearchRange: "setDateSearchRange"
    }),

    backButtonHandler() {
      if (this.showDetailedLog) {
        this.showDetailedLog = false;
        return false;
      }
      return true;
    },

    handleDateRangeChanged(val) {
      this.setDateSearchRange(val);
    },

    handleDetailedLogClick(task) {
      this.selectedTask = _.cloneDeep(task);
      this.setTaskForDetailedLogs(this.selectedTask);
      this.showDetailedLog = true;
    }
  }
};
</script>

<style lang="scss" scoped>
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
$card-image-filter: invert(65%) sepia(43%) saturate(5014%) hue-rotate(170deg)
  brightness(104%) contrast(89%);
@import "~@/styles/content-buttons.scss";
@import "~@/styles/content-card.scss";
@import "~@/styles/pagination-toolbar.scss";
@import "~@/styles/content-list.scss";

.form-container {
  padding: 20px 0;
}
</style>
